import React, { useEffect } from 'react'
import Seo from './../components/seo/seo'
import Layout from './../components/layout/Layout'

const Project = () => {
  useEffect(() => {
    setTimeout(() => {
      if (window.$(window).width() > 767) {
        var rellax = new window.Rellax('.rellax')
      }

      window.$('.tile').on('click', function (e) {
        window.$('body').addClass('hide-content')
        e.stopPropagation()
      })
    }, 1000)
  }, [])

  return (
    <>
      <Seo
        title={'Case Studies | immence'}
        description={
          'We absolutely love solving problems and transforming ideas into reality.'
        }
      />
      <Layout>
        <div>
          <section
            className="content-page bg-service project_banner"
            id="service_page"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content_padd">
                    <div className="row">
                      <h2 className="hero-heading">
                        It’s cool,
                        <br />
                        <span className="blue-span"> take a peek 👀 </span>
                      </h2>
                    </div>
                    <div className="row">
                      <p className="section-main-para heading-setting mb-0">
                        We count it a privilege to collaborate on so many
                        innovative concepts with outstanding businesses. We
                        absolutely love solving problems and transforming ideas
                        into reality.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 center-item">
                  <div className="service_img">
                    <img
                      src={require('../img/heroImg/case-studies.png').default}
                      alt
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*end hero section */}
          <div className="projectSec sectionPadding">
            <div className="container-fluid">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">Instruct Fitness</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_10" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              USA
                            </p>
                            <p className="type type_1">Health &amp; Fitness</p>
                          </div>
                          <p className="proname">Instruct Fitness</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">Koboldo</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_4" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              USA
                            </p>
                            <p className="type type_2">
                              Ecommerce &amp; Retail
                            </p>
                          </div>
                          <p className="proname">Koboldo</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">Plates Social</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_5" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              USA
                            </p>
                            <p className="type type_3">Food &amp; Social</p>
                          </div>
                          <p className="proname">Plates Social</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">Sapna Online</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_2" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              India
                            </p>
                            <p className="type type_4">Shopping Ecommerce</p>
                          </div>
                          <p className="proname">Sapna Online</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">JHD</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_15" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              UAE
                            </p>
                            <p className="type type_5">Travel &amp; Local</p>
                          </div>
                          <p className="proname">JHD</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">Weight Loss Direct</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_6" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              USA
                            </p>
                            <p className="type type_6">Health &amp; Fitness</p>
                          </div>
                          <p className="proname">Weight Loss Direct</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">ORE</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_12" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              Australia
                            </p>
                            <p className="type type_7">Education</p>
                          </div>
                          <p className="proname">ORE</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">Gaivota</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_9" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              Brazil
                            </p>
                            <p className="type type_8">Productivity</p>
                          </div>
                          <p className="proname">Gaivota</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">Truelink</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_13" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              India
                            </p>
                            <p className="type type_9">Communication</p>
                          </div>
                          <p className="proname">Truelink</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">Lets Shave</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_3" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              India
                            </p>
                            <p className="type type_10">Beauty</p>
                          </div>
                          <p className="proname">Lets Shave</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">E4Effort</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_8" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              USA
                            </p>
                            <p className="type type_11">Education</p>
                          </div>
                          <p className="proname">E4Effort</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">A2Z</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_1" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              Sweden
                            </p>
                            <p className="type type_12">Education</p>
                          </div>
                          <p className="proname">A2Z</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">WeXL</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_14" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              India
                            </p>
                            <p className="type type_13">Education</p>
                          </div>
                          <p className="proname">WeXL</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">HHA</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_11" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              UAE
                            </p>
                            <p className="type type_14">Auto &amp; Vehicles</p>
                          </div>
                          <p className="proname">HHA</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="animationBox">
                      <a className="projectList">
                        {/* <h4 class="projectName">WLD Community Portal</h4> */}
                        <div className="layout_images">
                          <div className="layout_container image_7" />
                        </div>
                        <div className="proInfo">
                          <div className="location">
                            <p className="country_name">
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 384 512"
                                >
                                  {/*! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                                  <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z" />
                                </svg>
                              </span>
                              USA
                            </p>
                            <p className="type type_15">Health &amp; Fitness</p>
                          </div>
                          <p className="proname">WLD Community Portal</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Project
